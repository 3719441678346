import {
  HomeDemo1Platform,
  HomeDemo1Rings,
} from "../../../utils/allImgs"

import SectionHeading from "../../../components/SectionHeading"

const OurPlatform = ({data}) => {

    return (

      <section className="about-us-area section-padding-100 clearfix" id="terms">
        <div className="container">

          <SectionHeading
            title="Terms"
            text={"Please read the terms of Weiller Ford before you place the order."}
            subtitle="Thank you for the order.  All transactions between you and Weiller Ford shall be governed by the terms and conditions listed below. By accepting the Terms of Purchase, you agree to all of the terms and conditions that are relevant to you. You declare, represent, and warrant that your company has given its approval for all purchases made by its employees or agents, or access granted to them in connection with Weiller Ford purchases. The Terms of Purchase must take precedence over all other documents in the case of a conflict, unless otherwise agreed to in writing by Weiller Ford."
          />
          {/* <p>Thank you for the order.  All transactions between you and Weiller Ford shall be governed by the terms and conditions listed below. By accepting the Terms of Purchase, you agree to all of the terms and conditions that are relevant to you. You declare, represent, and warrant that your company has given its approval for all purchases made by its employees or agents, or access granted to them in connection with Weiller Ford purchases. The Terms of Purchase must take precedence over all other documents in the case of a conflict, unless otherwise agreed to in writing by Weiller Ford.</p> */}
          <div className="row align-items-center">
            <div className="service-img-wrapper col-lg-5 col-md-12 col-sm-12 no-padding-right">
              <div className="features-list">
                <div className="who-we-contant">
                  <h4 className="w-text" data-aos="fade-up" data-aos-delay="200">Terms</h4>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">We offer Master Card, Visa, American Express, Discover Card, Money Purchase, Bank Wire Transfer, and Company Check as modes of payment, with a minimum order quantity of 200 euros. </p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">If you desire terms, you'll need to fill out a Credit Application or produce a Bank Letter of Credit. </p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">All items are priced at current prices, with the exception of those for which our pricing is directly wholesale or calculated from a written quote based on quantities. Collaboration terms and conditions are subject to change at any time. All prices are ex-works, which means they exclude packaging, freight, insurance, customs taxes, and VAT.</p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">RETURN CONDITIONS AND PROCEDURES </p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">Within 30 days after the consignment note's issue, returns are authorized. <br />Unless we make a mistake, the sender is always responsible for the cost. Our organization will bear the shipping charges in this case. <br />We do not accept things that have been used or are damaged in any way. <br /></p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">There will be no refunds if the following procedures are not followed. </p>
                  <p className="w-text" data-aos="fade-up" data-aos-delay="300">We do not accept things that have been used or are damaged in any way.</p>
                </div>
                {/* <ul className="list-marked">11
                  {data && data.map((item , key) => (
                      <li key={key} className="text-white"><i className="fa fa-check" />{item.icoName}</li>
                  ))}
                </ul>
                <a className="btn more-btn mt-30" href="#">Read More</a> */}
              </div>
            </div>
            <div className="service-img-wrapper col-lg-7 col-md-12 col-sm-12 mt-s">
              <div className="image-box">
                <img draggable="false" src={HomeDemo1Platform} className="center-block img-responsive phone-img" alt="" />
                <img draggable="false" src={HomeDemo1Rings} className="center-block img-responsive rings " alt="" />
              </div>
            </div> 
          </div>
        </div>
      </section>
    );

}

export default OurPlatform;