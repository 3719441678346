import ServicesPage from '../containers/HomeDemo1/services'

const About = () => {

  return (
    <>
    <br></br><br></br>
    <div className="clearfix" />
      <ServicesPage />
    </>
  );
}

export default About;

