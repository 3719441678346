import QuotePage from '../containers/HomeDemo1/quote'

const Quote = () => {

  return (
    <>
      <QuotePage />
    </>
  );
}

export default Quote;

