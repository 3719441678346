import AboutPage from '../containers/HomeDemo1/about'

const About = () => {

  return (
    <>
      <AboutPage />
    </>
  );
}

export default About;

