import HomeDemo1Container from '../containers/HomeDemo1'

const HomeDemo1 = () => {

  return (
    <>
      <HomeDemo1Container />
    </>
  );
}

export default HomeDemo1;

