import {
  About2
} from '../../utils/allImgs'

const SecAbout = ({imgDwon=false , title , text , img}) => {

  return (
    <>
      {imgDwon ? (
          <section className="about-us-area section-padding-100 clearfix" id="about">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 offset-lg-0">
                  <div className="who-we-contant mt-s">
                    <div className="dream-dots text-left" data-aos="fade-up" data-aos-delay="300">
                      <span className="gradient-text blue">{title}</span>
                    </div>
                    <h4 data-aos="fade-up" data-aos-delay="300">{text}</h4> 
                    <p data-aos="fade-up" data-aos-delay="300">Weiller Ford has more than 4 years of experience in the electronics sector and always takes great delight in upholding an unmatched commitment to integrity, quality, and complete client satisfaction. </p>
                    <p data-aos="fade-up" data-aos-delay="400">We understand how crucial it is to offer our consumers both top-notch products and great services. Keeping complete customer happiness at the forefront of its business strategy has helped Weiller Ford achieve great success. Our competent, well-trained, and amiable staff members constantly work to build enduring relationships with our clients. With our dedication to quality, we ensure the highest standards, consistently going above and beyond for our clients.</p>
                    {/* <a className="btn more-btn mt-30" href="#">Read More</a> */}
                  </div>
                </div>
                <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                  <div className="welcome-meter" data-aos="fade-up" data-aos-delay="200">
                    <img draggable="false" src={img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (

          <section className="about-us-area section-padding-100 clearfix" id="about">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                  <div className="welcome-meter" data-aos="fade-up" data-aos-delay="200">
                    <img draggable="false" src={img} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6 offset-lg-0">
                  <div className="who-we-contant mt-s">
                    <div className="dream-dots text-left" data-aos="fade-up" data-aos-delay="300">
                      <span className="gradient-text ">{title}</span>
                    </div>
                    <h4 data-aos="fade-up" data-aos-delay="300">{text}</h4>
                    <p data-aos="fade-up" data-aos-delay="300">Weiller Ford has more than 4 years of experience in the electronics sector and always takes great delight in upholding an unmatched commitment to integrity, quality, and complete client satisfaction. </p>
                    <p data-aos="fade-up" data-aos-delay="400">We understand how crucial it is to offer our consumers both top-notch products and great services. Keeping complete customer happiness at the forefront of its business strategy has helped Weiller Ford achieve great success. Our competent, well-trained, and amiable staff members constantly work to build enduring relationships with our clients. With our dedication to quality, we ensure the highest standards, consistently going above and beyond for our clients.</p>
                    {/* <a className="btn more-btn mt-30" href="#">Read More</a> */}
                  </div>
                </div>
              </div>
<br></br>
              <div className="row align-items-center">
                
                <div className="col-12 col-lg-6 offset-lg-0">
                  <div className="who-we-contant mt-s">
                    <h4 data-aos="fade-up" data-aos-delay="300">Many of the world's largest manufacturers recognize Weiller Ford as a reliable partner. </h4>
                    <p data-aos="fade-up" data-aos-delay="300">Weiller Ford has evolved into a reliable supply chain partner for all manufacturers, offering components and complete solutions for practically every application. Weiller Ford recognizes that manufacturers want comprehensive solutions. </p>
                    <p data-aos="fade-up" data-aos-delay="400">Our component portfolio is incredibly diverse, encompassing LEDs, sensors, MLCCs, controllers, and discrete components. We can promptly respond to client needs while maintaining an optimal balance of pricing, quality, and delivery. We'll shorten your time to market while keeping your supply chain flowing.</p>
                    {/* <a className="btn more-btn mt-30" href="#">Read More</a> */}
                  </div>
                </div>
                <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                  <div className="welcome-meter" data-aos="fade-up" data-aos-delay="200">
                    <img draggable="false" src={About2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        )}
    </>
  );
}

export default SecAbout;