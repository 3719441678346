import {
  Featured2, Featured3, Featured4, HomeDemo1Computer
} from '../../utils/allImgs'

const AboutOther = ({ClassTitle="gradient-text blue"}) => {

  return (

    <section className="about-us-area section-padding-100 clearfix" id='featured'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up" data-aos-delay="200">
                <span className={ClassTitle}>Featured Capabilities</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up" data-aos-delay="200">Weiller Ford has a range of featured capabilities that aims at making customer’s life easier</h4>
              <div className="fadeInUp" data-aos="fade-up" data-aos-delay="250">
                <p>-	Design</p>
                <p>-	Evaluation</p>
                <p>-	Environment & Safety Engineering</p>
                <p>-	Inspection & Testing Personnel</p>
                <p>-	Professional services</p>
                <p>-	Support Services</p>
                <p>-	Shipping</p>
                <p>-	Transportation</p>
                <p>-	Circular</p>
                <p>-	Actuators</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 mt-30 no-padding-left">
            <div className="welcome-meter floating-anim fadeInUp">
              <img draggable="false" src={HomeDemo1Computer} alt="" />
            </div>
          </div>
        </div>

        <div className="row dark-row pt-60">
        <div className="col-12 col-md-6 col-lg-4 mt-10">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <img src={Featured2} alt=''></img>
              <br></br>
              <h6>Superior Quality</h6>
              <p>Our primary objective is to serve our customers with only high-quality items.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-10">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <img src={Featured3} alt=''></img>
              <br></br>
              <h6>Free Samples</h6>
              <p>We offer free samples at the design stage.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-10">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <img src={Featured4} alt=''></img>  
              <br></br>
              <h6>Savings Program</h6>
              <p>We provide cost-effective options for large and recurrent orders.</p>
            </div>
          </div>
        </div>
      </div> 
      </div>
    </section>
  );
}

export default AboutOther;