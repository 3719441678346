
import Header from "../layouts/Header";
import FooterPages from '../layouts/Footer/FooterPages';

const Thanks = () => {

  return (
    <>
    <Header  />
       <section className="about-us-area section-padding-100 clearfix" id="about">
            <div className="container">
              <div className="row align-items-center">
                
                <div className="col-12 offset-lg-0">
                  <div className="who-we-contant mt-s">
                  <br></br>
                  <h4 data-aos="fade-up" data-aos-delay="300">Thanks for your submission </h4>
                    <p data-aos="fade-up" data-aos-delay="300">A representative will contact you within 24 hours or the next business day. Thank you for your enquiry. </p>
                    
                  </div>
                </div>
              </div>
<br></br>
              
            </div>
          </section>
          <FooterPages />
    </>
  );
}

export default Thanks;

