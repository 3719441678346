import{
  FooterPattern,
  FooterLogo,
  HeaderLogo
} from '../../../utils/allImgs'

import '../Footer.scss'
import TextFooter from '../../../data/data-layout/Footer/data-TextFooter.json'

const FooterPages = ({ClassSpanTitle=""}) => {
    return (

      <footer className="footer-area bg-img mt-5" style={{backgroundImage: `url(${FooterPattern})`}}>
        {/* ##### Contact Area Start ##### */}
       
        {/* ##### Contact Area End ##### */}
        <div className="footer-area bg-img" style={{backgroundColor: `#D7DFEF`}}>
          <div className="footer-content-area demo" style={{backgroundColor: '#D7DFEF'}}>
            <div className="container">
              <div className="row ">
                <div className="col-12 col-lg-4 col-md-6">
                  <div className="footer-copywrite-info">
                    <div className="copywrite_text fadeInUp" data-wow-delay="0.2s">
                      <div className="footer-logo">
                        <a href="#"><img draggable="false" src={HeaderLogo} alt="logo" /> </a>
                      </div>
                      <p>We strive to deliver superior quality products even if they are difficult to find.</p>
                    </div>
                    {/* <div className="footer-social-info fadeInUp" data-wow-delay="0.4s">
                      {IcoName && IcoName.map((item , key) => (
                          <a key={key} href="#"><i className={item.IcoName} aria-hidden="true" /></a>    
                      ))}
                    </div> */}
                  </div>
                </div>

                {TextFooter && TextFooter.map((item , key) => (
                  <div key={key} className={item.classBlock}>
                    <div className="contact_info_area d-sm-flex justify-content-between">
                      <div className={item.classInfo} data-wow-delay="0.3s">
                        <h5>{item.title}</h5>
                        <a href={!!item.click1 && item.click1}><p>{item.text1}</p></a>
                        <a href={!!item.click2 && item.click2}><p>{item.text2}</p></a>
                        <a href={!!item.click3 && item.click3}><p>{item.text3}</p></a>
                        <a href={!!item.click4 && item.click4}><p><a href='mailto:{item.text4}'>{item.text4}</a></p></a>
                        {item.text5 && <a href={!!item.click5 && item.click5}><p>{item.text5}</p></a>}
                        {!!item.text6 && <a href={!!item.click6 && item.click6}><p>{item.text6}</p></a>}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default FooterPages