import React, { useState } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import{
  FooterPattern,
  FooterLogo,
  HeaderLogo
} from '../../../utils/allImgs'

import SectionHeading from "../../../components/SectionHeading"

import '../Footer.scss'
import Thanks from '../../../pages/thanks';
import IcoName from '../../../data/data-layout/Footer/data-IcoName.json'
import TextFooter from '../../../data/data-layout/Footer/data-TextFooter.json'

const QuotePages = ({ClassSpanTitle=""}) => {

    const [cont_name, setcont_name] = useState();
   const [part_no, setpart_no] = useState();
   const [manufacturer, setmanufacturer] = useState();
   const [quantity_required, setquantity_required] = useState();
   const [cont_company, setcont_company] = useState();
   const [cont_email, setcont_email] = useState();
   const [remark, setremark] = useState('');
   const [message, setmessage] = useState('');

   const history = useHistory();

   const handleSubmit = (event) =>{
      event.preventDefault();
      const inputField = {cont_name:cont_name, cont_email:cont_email, part_no:part_no, manufacturer:manufacturer, quantity_required:quantity_required, cont_company:cont_company, remark:remark, website_id:'1081' };
      console.log(inputField);
      const API_PATH = 'http://54.145.7.92/sendemail/common_react.php';
      console.log(API_PATH);

      axios({
         method: 'POST',
         url: `${API_PATH}`,
         headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
         async: true,
         data: JSON.stringify(inputField),
         //datatype: 'json',
         mimeType: "text/html; charset=utf-8",
         mode: 'no-cors'
      })
      .then(response => {
         if(response.data == 1){
          history.push('/thanks');
         }
       })
       .catch(error => {
           console.error("Error:", error);
           setmessage('error in email sending');
          //console.log({msg});
       });

     }; 

    return (

      <div className="about-us-area section-padding-100 clearfix" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <SectionHeading
                  // title="Contact us"
                  text="RFQ"
                  ClassSpanTitle={ClassSpanTitle}
                />

              </div>
            </div>
            {/* Contact Form */}
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="contact_form">
                  <form onSubmit={handleSubmit} method="post" id="main_contact_form" noValidate>
                    <div className="row">
                      <div className="col-12">
                        <div id="success_fail_info" />
                        <div id="loader"></div>
                        <div id="message">{message}</div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.2s">
                          <input type="text" name='cont_name' onChange={e => setcont_name(e.target.value)} value={cont_name} required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Name</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" name='part_no' onChange={e => setpart_no(e.target.value)} value={part_no} required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Part Number</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" name='manufacturer' onChange={e => setmanufacturer(e.target.value)}  value={manufacturer} required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Manufacturer</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" ame='quantity_required' onChange={e => setquantity_required(e.target.value)}  value={quantity_required}  required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Quantity Required</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" name='cont_company' onChange={e => setcont_company(e.target.value)}  value={cont_company} required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Company Name</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" name='cont_email' onChange={e => setcont_email(e.target.value)}  value={cont_email} required />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Email Contact</label>
                        </div>
                      </div>
                      
                      <div className="col-12">
                        <div className="group fadeInUp" data-wow-delay="0.5s">
                          <textarea name='remark' onChange={e => setremark(e.target.value)} value={remark} required defaultValue={""} />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Comments</label>
                        </div>
                      </div>
                      <div className="col-12 text-center fadeInUp" data-wow-delay="0.6s">
                        <button type="submit" className="btn more-btn">SUBMIT FOR QUOTE</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

    );
}

export default QuotePages