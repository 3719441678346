import FeaturedPage from '../containers/HomeDemo1/featured'

const About = () => {

  return (
    <>
    <div className="clearfix" />
     <FeaturedPage />

      
    </>
  );
}

export default About;

