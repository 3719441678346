import TermsPage from '../containers/HomeDemo1/terms'

const About = () => {

  return (
    <>
      <TermsPage />
    </>
  );
}

export default About;

