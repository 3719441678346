import Aos from 'aos';
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";

import HomeDemo1 from './pages/HomeDemo1';


import 'aos/dist/aos.css';
import './assets/css/bootstrap.min.css';
import './assets/css/General.css';
import './assets/css/responsive.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import About from './pages/about';
import Services from './pages/services';
import Featured from './pages/featured';
import Quote from './pages/quote';
import Terms from './pages/terms';
import Thanks from './pages/thanks';

const App = () => {

  useEffect(() => {

    Aos.init({
      duration: 1000
      })
  },[])

  return (
    
    	<div className="App">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <title>
            NFT Marketplace
          </title>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0=" crossorigin="anonymous" />
        </Helmet>
  			<Switch>
          <Route path="/" exact component={HomeDemo1} />

          <Route path="/About" exact component={About} />

          <Route path="/Services" exact component={Services} />

          <Route path="/Featured" exact component={Featured} />

          <Route path="/Quote" exact component={Quote} />

          <Route path="/Terms" exact component={Terms} />
          <Route path="/Thanks" exact component={Thanks} />
          
          {/* <Route path="/" exact component={Home} />
  				<Route path="/index-demo-1" component={HomeDemo1} />
          <Route path="/index-demo-2" component={HomeDemo2} />
          <Route path="/index-demo-3" component={HomeDemo3} />
          <Route path="/index-demo-4" component={HomeDemo4} />
          <Route path="/index-demo-5" component={HomeDemo5} />
          <Route path="/index-demo-6" component={HomeDemo6} /> */}
  			</Switch>
	    </div>    
  );
}

export default App;