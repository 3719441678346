import {useEffect} from "react";
import {addRemoveClassBody} from '../../utils'

import {
        HomeDemo1Platform1,
        HomeDemo1Wwhitepaper,
        HomeDemo1About1
        } from '../../utils/allImgs'

import './style/HomeDemo1.scss'

import Header from "../../layouts/Header"
import FooterPages from '../../layouts/Footer/FooterPages'
import SecHeroSection from '../../components/HeroSection'

const HomeDemo1Container = () => {

    useEffect(() => {
      addRemoveClassBody('darker')
    },[])

    return (
      <div>
        <Header Title="Weiller Ford" />
        <SecHeroSection
          ClassSec="hero-section moving section-padding"
          ClassDiv="col-12 col-lg-5 col-md-12"
          specialHead="How We Serve Our Customers"
          title="Our Mission and Vision"
          link1="Learn More"
          link2="Quote"
          img={HomeDemo1Platform1}
        />
        <div className="clearfix" />
        <section className="about-us-area section-padding-100 clearfix" id="about">
            <div className="container">
      <div className="row dark-row pt-60">
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Supply</h6>
              <p>We ensure that on-time supply is done for your consignment.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Support</h6>
              <p>We offer extensive support from the start of the order to the end 24/7.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Testing</h6>
              <p>We offer third-party testing to test the products prior to shipping.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Smart Distribution</h6>
              <p>Weiller Ford provides a variety of services, the majority of which are configurable, such as supply chain solutions for component shortages, excess inventories, slow-moving stocks, hubbing items for warranty repair work, and staging product for manufacturing.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Cost Effective</h6>
              <p>The most significant demand placed on buying departments is to obtain the parts required for production at the lowest feasible cost without compromising product quality. With its PPV capabilities, Weiller Ford supplies these parts within the price range you choose.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mt-30">
          <div className="contribution-c-wapper">
            <div className="service_single_content text-center mb-30 fadeInUp" data-wow-delay="0.2s">
              <h6>Purchase Support</h6>
              <p>If you are encountering purchasing issues and it is becoming a struggle for your country, we can help you with purchasing services by offering a specialized buyer with onsite support.</p>
            </div>
          </div>
        </div>
      </div>  
      </div>
      </section>
        {/* <SecTrust data={SecTrustContent} /> */}
        {/* <OurServices data={Services} /> */}
        {/* <SmartContract data={SmartContractinfo} /> */}
        
        {/* <SecPrices data={SecPricesInfo} /> */}
       
        {/* <SpreadMap
            Wwhitepaper={HomeDemo1Wwhitepaper}
        /> */}
        {/* <TokenDistribution data={TokenDistributionInfo} /> */}
        {/* <Roadmap data={RoadmapInfo} /> */}
        {/* <Features2
            icoCounterClass="ico-counter mb-30"
            Features2InfoTop={Features2InfoTop}
            Features2InfoDown={Features2InfoDown}
        /> */}
        {/* <Faq data={FaqInfo} /> */}
        {/* <OurTeam data={OurTeamInfo} /> */}
        {/* <Subscribe /> */}
        {/* <OurBlog data={OurBlogInfo} /> */}
        <FooterPages />
      </div>
    );
};

export default HomeDemo1Container